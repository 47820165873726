<template>
<div>
  <NavBar name="公众号绑定"></NavBar>
  <div class="datapreview">
    <vxe-table ref="xTable1" :row-config="{ isHover: true }" :show-overflow="showOverflow1" :data="tableData" header-cell-class-name="table-header" cell-class-name="table-cell">
      <vxe-column field="appId" title="APPID"></vxe-column>
      <vxe-column field="appSecret" title="应用秘匙"></vxe-column>
      <vxe-column field="remarks" title="备注"></vxe-column>
      <vxe-column title="操作">
        <template #default="{row}">
          <pop tips="绑定" @myclick="binding(row)">
            <img class="icon" src="@/assets/img/icon/bangding.png" alt />
          </pop>
        </template>
      </vxe-column>
    </vxe-table>
  </div>

  <el-dialog v-model="visible" title="绑定/修改">
    <el-form label-width="120px" ref="form" :model="editForm" :rules="rules">
      <el-form-item label="APPID" prop="setting.appId">
        <el-input class="public-input" v-model="editForm.setting.appId" placeholder="输入APPID"></el-input>
      </el-form-item>
      <el-form-item label="应用秘匙" prop="setting.appSecret">
        <el-input class="public-input" v-model="editForm.setting.appSecret" placeholder="输入应用密匙"></el-input>
      </el-form-item>
      <el-form-item label="备注">
        <el-input class="public-input" v-model="editForm.setting.remarks" placeholder="输入备注"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <myButton @click="visible = false">取消</myButton>
      <myButton type="primary" @click="handedit" left>确认</myButton>
    </template>
  </el-dialog>
</div>
</template>

<script>
export default {
  data() {
    return {
      showOverflow1: false,
      tableData: [],
      page: 1,
      visible: false,
      editForm: {
        setting: {
          appId: '',
          appSecret: '',
          remarks: ''
        }
      },
      rules: {
        'setting.appId': [{
          required: true,
          message: "请输入APPID",
          trigger: "blur"
        }],
        'setting.appSecret': [{
          required: true,
          message: "请输入appSecret",
          trigger: "blur"
        }]
      }
    };
  },
  created() {
    this.getList();
  },
  methods: {
    binding(row) {
      this.editForm = {
        setting: {
          appId: row.appId,
          appSecret: row.appSecret,
          remarks: row.remarks
        }
      };
      this.visible = true;
    },
    getList() {
      this.$request.HttpPost('/community/property/get-setting').then(res => {
        if (res.data) {
          this.tableData = [JSON.parse(res.data)];
        } else {
          this.tableData = [{}];
        }
      });
    },
    handedit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$request.HttpPost('/community/property/setting', this.editForm).then(res => {
            this.visible = false;
            this.getList();
          });
        }
      })
    }
  },
};
</script>
<style lang="scss">
.datapreview {
    margin-top: 20px;
}
.ysall {
    display: inline-block;
    width: 160px;
    height: 32px;
    background: #02ce80;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    padding: 0 10px;
    box-sizing: border-box;
    line-height: 32px;
    span {
        &:nth-child(2) {
            font-weight: bold;
            margin-left: 12px;
        }
    }
}
</style>
